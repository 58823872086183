$(document).ready(function () {

	$(".page").addClass("page--scroll");

	// mobile menu toggle
		var scrollPositionGlobal;
		var targetElement = $(".page-header__content-inner");

	$(".js-main-nav-toggle").on("click", function () {

		var scrollPosition = $(window).scrollTop();

		$(this).toggleClass("is-active");
		if ($(this).hasClass("is-active")) {
			$(".page-header").addClass("page-header--open");
			$(".page").removeClass("page--scroll");
			bodyScrollLock.disableBodyScroll(targetElement);

		} else {
			$(".page-header").removeClass("page-header--open");
			$(".page").addClass("page--scroll");
			bodyScrollLock.enableBodyScroll(targetElement);
		}
	});

	$(".js-main-nav-link").on("click", function() {

		if ($(".js-main-nav-toggle").hasClass("is-active")) {
			$(".js-main-nav-toggle").removeClass("is-active");
			$(".page-header").removeClass("page-header--open");
			bodyScrollLock.enableBodyScroll(targetElement);
			$(".page").addClass("page--scroll");
		}
	});


	function checkMobileMenu() {
		var windowWidth = $(window).width();

		var mobileMenu = $(".js-main-nav-toggle");
		if (windowWidth >= 1280 && mobileMenu.hasClass("is-active")) {
			$(".js-main-nav-toggle").removeClass("is-active");
			$(".page-header").removeClass("page-header--open");
			bodyScrollLock.enableBodyScroll(targetElement);
			$(".page").addClass("page--scroll");
		}
	}

	// mobile filter toggle

	$(".js-filter-toggle").on("click", function () {
		$(this).toggleClass("is-active");
		if ($(this).hasClass("is-active")) {
			$(".catalog-filter__type").slideDown(500);
			$(".catalog-filter__product").slideDown(500);
		} else {
			$(".catalog-filter__type").slideUp(500);
			$(".catalog-filter__product").slideUp(500);
		}
	});

	// photo slider

	photoSliderParams = {
		direction: 'horizontal',
		slidesPerView: 1,
		navigation: {
			prevEl: '.js-photo-slider-btn-prev',
			nextEl: '.js-photo-slider-btn-next',
		},
	};

	var newsSlider = new Swiper (".js-photo-slider", photoSliderParams);

	// info slider

	infoSliderParams = {
		direction: 'horizontal',
		slidesPerView: 1,
		navigation: {
			prevEl: '.js-info-slider-btn-prev',
			nextEl: '.js-info-slider-btn-next',
		},
		watchOverflow: true,
		breakpointsInverse: true,
		breakpoints: {
			1024: {
				slidesPerView: 3,
				spaceBetween: 30
			},
			1280: {
				slidesPerView: 3,
				spaceBetween: 30
			},
			1800: {
				slidesPerView: 3,
				spaceBetween: 60
			}
		}
	};

	var newsSlider = new Swiper (".js-info-slider", infoSliderParams);

	// order info slider

	orderInfoSliderParams = {
		direction: 'horizontal',
		slidesPerView: 1,
		navigation: {
			prevEl: '.js-order-info-slider-btn-prev',
			nextEl: '.js-order-info-slider-btn-next',
		},
		watchOverflow: true,
		breakpointsInverse: true,
		breakpoints: {
			1024: {
				slidesPerView: 'auto',
				//spaceBetween: 20
			},
		}
	};

	var newsSlider = new Swiper (".js-order-info-slider", orderInfoSliderParams);

	// product slider

	var productSliderThumbs = new Swiper('.js-product-slider-thumbs', {
		spaceBetween: 20,
		slidesPerView: 'auto',
		freeMode: false,
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
		breakpointsInverse: true,
		breakpoints: {
			1800: {
				spaceBetween: 40
			}
		}
	});

	productSliderParams = {
		direction: 'horizontal',
		slidesPerView: 1,
		pagination: {
			el: '.js-product-slider-pagination',
			type: 'bullets',
			clickable: true
		},
		thumbs: {
			swiper: productSliderThumbs,
			slideThumbActiveClass: 'product-slider-thumbs__item--active',
		}
	};

	var productSlider = new Swiper (".js-product-slider", productSliderParams);

	$(".js-select").selectric();

	$(".js-close-message").on("click", function () {
		$(this).parent().fadeOut(300);
	});

	// modal

	$('[data-modal-open]').on('click', function(e) {
		e.preventDefault();
		var target = $(this).attr('data-modal-open');
		$("html").addClass("modal-lock");
		$("body").addClass("no-scroll");

		$('#' + target).fadeIn(200,
			function () {
				$(this).find(".modal__inner")
					.css("display", "block")
					.animate({opacity: 1}, 200);

			}
		);
	});

	$('[data-modal-close]').on('click', function(e) {
		e.preventDefault();
		var target = $(this).attr('data-modal-close');
		$('#' + target).find(".modal__inner")
			.animate({opacity: 0}, 200)
			.css("display", "none");

		$('#' + target).fadeOut(200, function () {
			$("body").removeClass("no-scroll");
			$("html").removeClass("modal-lock");
		});
	});

	var headerHeight = $(".page-header").innerHeight();

	$(window).scroll(function() {

		var sticky = $('.page-header'),
				scroll = $(window).scrollTop(),
				screen = $(window).height() / 2;

		if (scroll > headerHeight) {
			sticky.addClass('page-header--invisible');
		} else {
			sticky.removeClass('page-header--invisible');
		}

		if (scroll >= screen) {
			$(".page__main").css("padding-top", headerHeight + "px");
			sticky.addClass('page-header--fixed');
		} else {
			$(".page__main").css("padding-top", "0");
			sticky.removeClass('page-header--fixed');
		}
	});

	var resizeTimer;

	$(window).on('resize', function(e) {
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(function () {
			checkMobileMenu();
		}, 100);
	});

});